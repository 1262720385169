import React, { useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Button } from '@kyivstarteam/ks-react-design-system-public'
import { observer } from 'mobx-react-lite'
import { AppStoreIcon, GooglePlayIcon, CloseIcon } from '@/components/icons'
import { useTranslation } from 'next-i18next'
import AppConfig from '@/AppConfig'
import styles from './FavoriteModal.module.scss'
import HeartIcon from '../icons/HeartIcon'

interface Props {
  open: boolean
  onClose: () => void
  initialView: 'view1' | 'view2'
}

export const Dialog = DialogPrimitive.Root

function FavoriteModal({ open, onClose, initialView = 'view1' }: Props) {
  const { t } = useTranslation()
  const [view, setView] = useState<'view1' | 'view2'>(initialView)

  React.useEffect(() => {
    setView(initialView)
  }, [initialView])

  const handleOnClose = () => {
    setView('view1')
    onClose()
  }

  const renderCloseButton = () => (
    <Button variant="quiet" className="p-0 h-8 w-8" onClick={handleOnClose}>
      <CloseIcon />
    </Button>
  )

  const handleAppStoreNav = () => {
    window.open(AppConfig.appLink, '_blank')
  }

  const renderButtons = () =>
    view === 'view1' ? (
      <div className="grid gap-3 grid-rows-2">
        <Button variant="primary" fullWidth onClick={() => setView('view2')}>
          {t(`favoriteModal.useDJingle`)}
        </Button>
        <Button variant="tertiary" className={styles.closeButton} fullWidth onClick={handleOnClose}>
          {t('favoriteModal.close')}
        </Button>
      </div>
    ) : (
      <div className="grid gap-3 grid-rows-2">
        <Button variant="primary" className="bg-black active:bg-black" fullWidth onClick={handleAppStoreNav}>
          <AppStoreIcon width={120} height={75} color="#fff" />
        </Button>
        <Button variant="primary" className="bg-black active:bg-black" fullWidth onClick={handleAppStoreNav}>
          <GooglePlayIcon width={120} height={67} color="#fff" />
        </Button>
      </div>
    )

  const renderHeader = () => (
    <div className="flex justify-center">
      <div
        className={`${styles.circle} flex items-center overflow-hidden ${
          view === 'view1' ? 'justify-center' : 'justify-end'
        }`}>
        {view === 'view1' ? (
          <HeartIcon variant="yellow" />
        ) : (
          <svg width={47} height={22} fill="none">
            <circle cx={45.5} cy={11} r={10} fill="#FEE32D" stroke="#FFF196" />
            <circle cx={10.5} cy={11} r={10} fill="#FEE32D" stroke="#FFF196" />
          </svg>
        )}
      </div>
    </div>
  )

  return (
    <Dialog open={open} modal>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content>
          <div className={styles.overlay}>
            <div className={`${styles.container} modal-xl xs:modal flex flex-col xs:m-4`}>
              <div className="flex flex-row justify-end">{renderCloseButton()}</div>
              {renderHeader()}
              <div>
                <div className="flex flex-col pt-8 pb-4 xs:pt-4 xs:pb-2">
                  <p className="text-xl text-white font-bold">{t(`favoriteModal.${view}.header`)}</p>
                </div>
                <div className="xs:pb-6 pb-11">
                  <p className={`text-sm ${styles.textDescription}`}>{t(`favoriteModal.${view}.description`)}</p>
                </div>
              </div>
              {renderButtons()}
            </div>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </Dialog>
  )
}

export default observer(FavoriteModal)
