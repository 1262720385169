import axios from 'axios'
import ApiConfig from '@/services/api.config'

const apiClient = axios.create({
  baseURL: ApiConfig.baseApiUrl,
  timeout: 3000,
  responseType: 'json',
  headers: {
    'X-API-KEY': ApiConfig.X_API_KEY
  }
})

const apiWebClient = axios.create({
  baseURL: ApiConfig.baseApiUrlWeb,
  timeout: 3000,
  responseType: 'json',
  headers: {
    'X-API-KEY': ApiConfig.X_API_KEY
  }
})

export { apiClient, apiWebClient }
