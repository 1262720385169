const cdn = process.env.NEXT_PUBLIC_CDN || 'https://radio.kyivstar.ua'
const streamUrl = process.env.NEXT_PUBLIC_STREAM_URL || ''

export default {
  cdn,
  streamUrl,
  imagesUri: '/images',
  appLink: 'https://radioks.page.link/Favorites',
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION
}
