import { Heading } from '@/components/Typography'
import { PhoneNumber } from '@kyivstarteam/ks-react-design-system-public'
import { PhoneIcon } from '@/components/icons'
import React from 'react'

const Contact = ({ heading }: { heading: string }) => (
  <div className="xs:border-t border-solid border-gray-700 pt-7 pb-6 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">
    <Heading>{heading}</Heading>
    <div className="space-x-2 pt-4">
      <PhoneNumber phoneNumber="466" phoneSize="m" icon={<PhoneIcon />} />
      <PhoneNumber phoneNumber="0 800 300 466" phoneSize="m" icon={<PhoneIcon />} />
    </div>
  </div>
)

export default Contact
