import * as React from 'react'

function PhoneIcon({ color, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.704 9.998c.628 1.3 1.998 2.67 3.298 3.298.262.126.571-.035.724-.283.294-.477.938-1.179 2.274-1.513 2.129-.532 5 1.371 5 3.5 0 1.597-.838 3.5-3.5 3.5-3.194 0-5.846-1.832-8.507-4.493C3.332 11.346 1.5 8.693 1.5 5.5 1.5 2.839 3.402 2 5 2c2.128 0 4.032 2.871 3.5 5-.334 1.336-1.036 1.98-1.513 2.274-.248.153-.41.462-.283.724z"
        stroke={color || '#50A7FF'}
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default React.memo(PhoneIcon)
