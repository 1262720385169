import React from 'react'
import Link from 'next/link'

const IconHolder = ({
  children,
  className = '',
  href,
  target = '_blank'
}: {
  children?: React.ReactNode
  className?: string
  href: string
  target?: string
}) => (
  <Link href={href}>
    <a
      target={target}
      className={`${className} w-8 h-8 rounded-full border-2 border-solid border-blue-500 flex items-center justify-center`}>
      <span className="text-blue-600 font-bold xs:text-sm">{children}</span>
    </a>
  </Link>
)

export default IconHolder
