import { Heading } from '@/components/Typography'
import { AppStoreIcon, GooglePlayIcon } from '@/components/icons'
import React from 'react'
import IconHolder from './IconHolder'

const TakeMore = ({ heading }: { heading?: string }) => (
  <div className="flex flex-col xs:w-full xs:pt-8">
    <Heading>{heading}</Heading>
    <div className="flex flex-row space-x-2 pt-2 xs:pt-4">
      <IconHolder href="https://apps.apple.com/us/app/id1275076288" className="w-24 h-8 xs:w-full xs:flex-1 xs:h-8">
        <AppStoreIcon />
      </IconHolder>
      <IconHolder
        href="https://play.google.com/store/apps/details?id=com.radioks"
        className="w-24 h-8 xs:w-full xs:flex-1 xs:h-8">
        <GooglePlayIcon />
      </IconHolder>
    </div>
  </div>
)

export default TakeMore
