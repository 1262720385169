import { Label } from '@kyivstarteam/ks-react-design-system-public'
import React from 'react'
import styles from './Typography.module.scss'

export const H1 = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <Label className={`${styles.H1} ${className}`}>{children}</Label>
)

export const Heading = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
  <Label className={`${styles.heading} ${className}`}>{children}</Label>
)

export const Title = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
  <Label className={`${styles.title} ${className}`}>{children}</Label>
)

export const Copyright = ({ children }: { children: React.ReactNode }) => (
  <Label className={styles.copyright}>{children}</Label>
)

export const Legal = ({ children }: { children: React.ReactNode }) => <Label className={styles.legal}>{children}</Label>

export const Lbl = ({
  children,
  htmlFor,
  className = ''
}: {
  children: React.ReactNode
  htmlFor: string
  className?: string
}) => (
  <label htmlFor={htmlFor} className={`${styles.title} ${className}`}>
    {children}
  </label>
)
