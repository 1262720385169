import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={248} height={90} viewBox="0 0 248 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.634 36.212c-.013-1.315-1.074-2.385-2.383-2.385h-12.394a2.395 2.395 0 00-2.389 2.386V87.6a2.396 2.396 0 002.389 2.4h12.394a2.393 2.393 0 002.383-2.399V36.212zM109.288 87.2L90.131 35.96c-.453-1.345-1.52-2.133-3.165-2.133H72.509c-1.643 0-2.713.788-3.152 2.133L50.196 87.2c-.579 1.536.288 2.8 1.92 2.8h12.188c.983 0 2.046-.766 2.37-1.7l2.479-7.52h21.175l2.48 7.52c.326.934 1.39 1.7 2.372 1.7h12.185c1.632 0 2.502-1.264 1.923-2.8zM73.559 69.036l6.188-18.427 6.184 18.427H73.56zm145.24-20.98c-7.337 0-12.663 5.69-12.663 13.534 0 8.217 5.326 14.183 12.663 14.183 7.34 0 12.663-5.966 12.663-14.183 0-7.844-5.323-13.534-12.663-13.534zm0 41.942c-16.46 0-28.404-11.947-28.404-28.408 0-16.084 11.944-27.761 28.404-27.761 16.467 0 28.411 11.677 28.411 27.761 0 16.46-11.944 28.408-28.411 28.408zM133.263 75.29c8.425 0 13.067-5.011 13.067-14.108 0-8.955-4.642-13.885-13.067-13.885h-3.905V75.29h3.905zm-20.871 12.776V35.764c0-1.07.869-1.936 1.936-1.936h18.44c17.855 0 28.94 10.72 28.94 27.976 0 17.393-11.085 28.197-28.94 28.197h-18.44a1.935 1.935 0 01-1.936-1.933z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.18 14.205c0 7.846-6.329 14.202-14.133 14.202-7.801 0-14.125-6.356-14.125-14.202S168.246 0 176.047 0c7.804 0 14.133 6.359 14.133 14.205z"
        fill="#F4D400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.471 71.34c8.368-2.338 14.063-8.477 14.063-18.112 0-11.789-9.861-19.399-21.653-19.399H2.406A2.402 2.402 0 000 36.226h.006L0 87.602a2.402 2.402 0 002.406 2.397H13.31a2.396 2.396 0 002.397-2.397V72.42h.048L27.46 88.068c.964 1.18 1.93 1.93 3.331 1.93h12.685c1.83 0 2.468-2.02 1.51-3.22L33.471 71.34zM15.706 46.802h8.78c5.573 0 7.508 2.571 7.508 6.326 0 3.212-1.504 6.537-7.29 6.537h-8.998V46.802z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
