import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4 3.42a6.02 6.02 0 01-1.701.479 2.998 2.998 0 001.299-1.674 5.81 5.81 0 01-1.872.733A2.924 2.924 0 0010.969 2C9.335 2 8.02 3.36 8.02 5.029c0 .24.02.47.069.69-2.455-.123-4.626-1.33-6.085-3.167a3.108 3.108 0 00-.405 1.53c0 1.05.527 1.979 1.312 2.517a2.86 2.86 0 01-1.334-.373v.033c0 1.471 1.023 2.694 2.365 2.975-.24.068-.502.1-.774.1-.189 0-.38-.011-.559-.052.383 1.2 1.468 2.08 2.759 2.11A5.838 5.838 0 011 12.641 8.16 8.16 0 005.529 14c5.432 0 8.402-4.615 8.402-8.616a8.29 8.29 0 00-.01-.391A5.975 5.975 0 0015.4 3.42z"
        fill="#50A7FF"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
