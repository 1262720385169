import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.58 1A2.587 2.587 0 001 3.593v8.814A2.587 2.587 0 003.58 15h3.956v-4.826H5.789V7.98h1.747V6.225a2.626 2.626 0 012.62-2.632h1.747v2.193h-.988c-.428-.004-.759-.006-.759.44V7.98h2.184l-.873 2.194h-1.31V15h2.262A2.586 2.586 0 0015 12.407V3.593A2.587 2.587 0 0012.42 1H3.58z"
        fill="#50A7FF"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
