import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.813 8A2.19 2.19 0 008 10.188 2.19 2.19 0 0010.188 8 2.19 2.19 0 008 5.812 2.19 2.19 0 005.812 8z"
        fill="#50A7FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.593A2.587 2.587 0 013.58 1h8.84C13.844 1 15 2.16 15 3.593v8.814A2.587 2.587 0 0112.42 15H3.58A2.587 2.587 0 011 12.407V3.593zm10.53.932a.466.466 0 100-.932.466.466 0 000 .932zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"
        fill="#50A7FF"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
