import '@/styles/global.scss'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import { RootStoreProvider } from '@/stores/RootStoreProvider'
import Layout from '@/components/Layout'
import * as amplitude from '@amplitude/analytics-browser'
import ErrorBoundary from '@/components/ErrorBoundary'
import { GoogleAnalytics, usePageViews, event } from 'nextjs-google-analytics'
import { useEffect } from 'react'
import AppConfig from '@/AppConfig'

// Will be called once for every metric that has to be reported.
export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Custom Metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true // avoids affecting bounce rate.
  })
}

const App = ({ Component, pageProps }: AppProps<{ hydrationData: any } & SSRConfig>) => {
  usePageViews()

  useEffect(() => {
    console.log('app started', AppConfig)
    amplitude.init(AppConfig.amplitudeApiKey, null, {
      logLevel: amplitude.Types.LogLevel.Warn,
      appVersion: AppConfig.appVersion
    })
    amplitude.track('browser app started')
  }, [])

  return (
    <RootStoreProvider hydrationData={pageProps.hydrationData}>
      <Layout>
        <GoogleAnalytics />
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Layout>
    </RootStoreProvider>
  )
}

export default appWithTranslation(App)
