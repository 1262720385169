import * as React from 'react'
import { SVGProps } from 'react'

const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.5 8.333c1.333.77 1.333 2.694 0 3.464L7 17.86c-1.333.77-3-.192-3-1.732V4.003c0-1.54 1.667-2.502 3-1.732l10.5 6.062Z"
      fill="#0F1114"
    />
  </svg>
)

export default PlayIcon
