import React from 'react'
import Image from 'next/image'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { Button } from '@kyivstarteam/ks-react-design-system-public'
import { useRouter } from 'next/router'
import styles from './Error.module.scss'
import spaceman from './spaceman.svg'

interface Props {
  header?: string
  message?: string
}

export default function Error({ header, message }: Props) {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <div className="flex py-16">
      <Head>
        <title>{`${t('title')} :: ${t('errorHeader')}`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="flex justify-center items-center pb-6 xs:pb-4">
          <Image src={spaceman} />
        </div>
        <div className="flex flex-col px-4 max-w-md">
          <p className={`${styles.header} pt-4 text-center`}>{header || t('errorHeader')}</p>
          <p className={`${styles.message} pt-2 text-center`}>{message || t('errorMessage')}</p>
        </div>
        <div className="flex flex-1 justify-center py-4">
          <Button variant="primary" onClick={() => router.reload()}>
            {t('tryAgain')}
          </Button>
        </div>
      </div>
    </div>
  )
}
