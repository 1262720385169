import React from 'react'
import { DotIcon, RadioIcon } from '@/components/icons'

export default function Header({ children, onClick }: { children?: React.ReactNode; onClick: () => void }) {
  return (
    <header className="flex flex-row w-full pt-9 xs:pt-3 pb-12 xs:pb-4">
      <div className="flex flex-grow justify-center xs:justify-start xs:items-center xs:pl-4 xs:pb-2 relative overflow-hidden">
        <div className="flex relative">
          <a href="https://radio.kyivstar.ua">
            <RadioIcon className="xs:logo-small transition-all" />
          </a>
        </div>

        <div className="absolute bottom-3 xs:bottom-2 xs:right-2 -right-3">
          <button type="button" onClick={onClick} className="p-0">
            <div className="flex flex-row justify-end items-center flex-grow space-x-5 xs:space-x-2">
              <DotIcon className="xs:dot-small transition-all" />
              <DotIcon className="xs:dot-small transition-all" />
            </div>
          </button>
        </div>
      </div>

      {children}
    </header>
  )
}
