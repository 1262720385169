import { Stream, Title } from '@/services/api.types'
import AppConfig from '@/AppConfig'

export const parseTitle = (title: string, defaultArtist?: string, defaultSong?: string) => {
  if (!title || title.startsWith('ad_')) return { artist: defaultArtist, song: defaultSong }
  const [artist, song] = title.split(' - ').map((value) => value.trim())
  return {
    artist: artist || defaultArtist,
    song: song || defaultSong
  }
}

export const safeImage = (streamId: string, title: Title, squared = false) => {
  const image = title?.image
  return image && image !== 'img/nocover.jpg'
    ? `${AppConfig.cdn}/radio24/${image}`
    : `/images/${squared ? 'squared/' : ''}${streamId}.png`
}

export const safeNextImage = (streamId: string, title: Title, width: number, height: number, squared = false) => {
  const imageUri = safeImage(streamId, title, squared)
  return `/_next/image?url=${encodeURIComponent(imageUri)}&w=${width}&h=${height}&q=75`
}

export const safeCurrentImage = (stream: Stream) => safeImage(stream.id, stream.current)

export function chunk<T>(array: T[], size: number): Array<Array<T>> {
  return (array || []).reduce((previousValue, currentValue, index) => {
    const bucket = index % size
    // eslint-disable-next-line no-param-reassign
    if (!previousValue[bucket]) previousValue[bucket] = []
    previousValue[bucket].push(currentValue)
    return previousValue
  }, [])
}
