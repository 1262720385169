import { observer } from 'mobx-react-lite'
import React from 'react'
import Hls from 'hls.js'
import ReactPlayer from 'react-player'
import { useRootStore } from '@/stores/RootStoreProvider'

export default observer(() => {
  const store = useRootStore()
  const audioEl = React.useRef<ReactPlayer>(null)
  const hlsInstanceRef = React.useRef(null)
  const { playing, volume, streamUrl, offline, playingStreamId, playingStream } = store
  const { isMobileSafari } = store.selectors || {}

  React.useEffect(() => {
    if (!hlsInstanceRef.current) return
    if (!playing) {
      // stop playlist/fragment loading
      hlsInstanceRef.current.stopLoad()
    } else {
      // Start/restart playlist/fragment loading.
      hlsInstanceRef.current.startLoad(-1)
    }
  }, [playing])

  const hlsOptions = {
    debug: false,
    enableWorker: true,
    defaultAudioCodec: 'mp4a.40.2',
    liveSyncDurationCount: 90,
    liveMaxLatencyDurationCount: 91,
    startLevel: -1,
    testBandwidth: true,
    startFragPrefetch: true // Start prefetching start fragment although media not attached yet.
  }

  const handleOnReady = (player) => {
    console.log('hls player is ready')
    const hls = player?.getInternalPlayer('hls')
    if (!hls) {
      console.warn('HLS player not found')
      return
    }
    hlsInstanceRef.current = hls
    store.setLatency(hls.latency)
    hls.on(Hls.Events.FRAG_CHANGED, () => {
      store.setLatency(hls.latency)
    })
    hls.on(Hls.Events.MEDIA_ATTACHED, (event, data) => {
      console.log('Hls.Events.MEDIA_ATTACHED:', data)
    })
    hls.on(Hls.Events.MEDIA_DETACHED, (event, data) => {
      console.log('Hls.Events.MEDIA_DETACHED:', data)
    })
    hls.on(Hls.Events.ERROR, (event, data) => {
      console.log('Hls.Events.ERROR:', data)
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log('fatal network error encountered, try to recover')
            hls.startLoad() // Start/restart playlist/fragment loading.
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, try to recover')
            hls.recoverMediaError()
            break
          default:
            // cannot recover
            console.log('fatal media error encountered, cannot recover, destroying')
            hls.destroy()
            store.pause()
            break
        }
      } else if (data.type === Hls.ErrorTypes.MEDIA_ERROR && data.details === 'bufferStalledError') {
        console.log('bufferStalledError, try to recover')
        store.pause()
        store.play(playingStreamId)
      }
    })
    hls.on(Hls.Events.BUFFER_EOS, (event, data) => {
      console.error('Hls.Events.BUFFER_EOS:', data)
      store.pause()
    })
    hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
      const {
        details: { advanced }
      } = data
      if (!advanced) {
        console.warn('Missed next level', advanced)
      }
    })
  }

  return (
    <ReactPlayer
      height={0}
      width={0}
      playing={playing}
      volume={volume}
      ref={audioEl}
      url={streamUrl}
      config={{
        file: {
          forceAudio: true,
          forceHLS: !isMobileSafari,
          hlsOptions
        }
      }}
      onEnded={() => {
        console.error('onEnded')
        if (offline) {
          // На экране отображается всплывающее окно с текстом "На жаль, музика на паузі поки знову не з’явиться інтернет".
        }
        if (store.playing) store.stop()
      }}
      onError={(error, data) => {
        console.error(error, data)
      }}
      onReady={handleOnReady}
    />
  )
})
