import { useEffect } from 'react'
import { useRootStore } from '@/stores/RootStoreProvider'

const useOffline = () => {
  const store = useRootStore()
  useEffect(() => {
    const offline = () => store.setOffline(true)
    const online = () => store.setOffline(false)
    window.addEventListener('offline', offline)
    window.addEventListener('online', online)
    return () => {
      window.removeEventListener('offline', offline)
      window.removeEventListener('online', online)
    }
  }, [])
}

export default useOffline
