import * as React from 'react'
import { SVGProps } from 'react'

const MusicNoteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={5.343} cy={20.46} r={2.5} stroke="#fff" strokeWidth={2} />
    <circle cx={16.343} cy={17.46} r={2.5} stroke="#fff" strokeWidth={2} />
    <path
      d="M7.843 20.46V8.724a1 1 0 0 1 .737-.965l9-2.454a1 1 0 0 1 1.263.964v11.142M7.69 12.358l11.306-3.03"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M4.308 7.726s-.536-1.586.707-2.828c1.242-1.243 2.828-.707 2.828-.707M1.397 6.23S.952 4.195 2.632 2.515C4.312.835 6.347 1.28 6.347 1.28"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export default MusicNoteIcon
