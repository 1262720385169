import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.8A6.8 6.8 0 1 0 8 1.2a6.8 6.8 0 0 0 0 13.6ZM8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
      fill="#fff"
    />
    <path d="M7 4.4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 7.2a.6.6 0 0 1 .6-.6h1.2a.6.6 0 0 1 .6.6v4h1a.6.6 0 1 1 0 1.2H6.6a.6.6 0 1 1 0-1.2h1V7.8H7a.6.6 0 0 1-.6-.6Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
