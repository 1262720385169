/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Error from '@/components/Error'
import * as Sentry from '@sentry/nextjs'

class ErrorBoundary extends React.Component<{ children: React.ReactChild }, { hasError: boolean }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
    console.error('Client error has occurred:', { error, errorInfo })
  }

  render() {
    return this.state.hasError ? <Error /> : this.props.children
  }
}

export default ErrorBoundary
