/* App config for apis */
const ApiConfig = {
  baseApiUrl: process.env.NEXT_PUBLIC_BASE_API_URL,
  baseApiUrlWeb: process.env.NEXT_PUBLIC_BASE_API_URL_WEB,
  X_API_KEY: process.env.NEXT_PUBLIC_X_API_KEY,
  NOW_PLAYING: '/v2/nowplaying',
  djingle: '/djingle/media'
}

export default ApiConfig
