import { makeAutoObservable, runInAction } from 'mobx'
import fetchStreams from '@/services/fetchStreams'
import fetchStream from '@/services/fetchStream'
import { DJingle, Stream, Title } from '@/services/api.types'
import AppConfig from '@/AppConfig'
import isEquals from 'lodash.isequal'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/nextjs'
import { event } from 'nextjs-google-analytics'
import * as amplitude from '@amplitude/analytics-browser'
import fetchJingles from '@/services/fetchJingles'

export type RootStoreHydration = {
  streams: Stream[]
  selectedStreamId: string
  selectors: any
}

function enhanceStream(stream: Stream) {
  let displayName: string
  switch (stream.id) {
    case 'rap':
      // Rename 'HipHop & RnB' to 'Urban'
      displayName = 'Urban'
      break
    case 'sport':
      // Rename 'Sport' to 'Happy Hits'
      displayName = 'Happy Hits'
      break
    default:
      displayName = stream.displayName
      break
  }
  return {
    ...stream,
    displayName
  }
}

export class RootStore {
  public selectors
  public streams: Stream[]
  public playingStreamId: string
  public selectedStreamId: string
  public playing = false
  public isHD = true
  public status: 'pending' | 'done' | 'error'
  public error: any
  public volume = 0.5
  public latency
  public offline = false
  public hasHD = true
  public favoriteSelected: Title
  public openFavoriteModal: boolean
  public jingles: DJingle[]

  constructor() {
    makeAutoObservable(this)
  }

  public setOffline(offline: boolean) {
    this.offline = offline
  }

  public setStreams(streams: Stream[]) {
    this.streams = streams?.map(enhanceStream)
  }

  public updateStream(stream: Stream) {
    const index = this.streams.findIndex(({ id }) => id === stream.id)
    if (index !== -1 && !isEquals(this.streams[index], stream)) {
      this.streams[index] = enhanceStream(stream)
    }
  }

  public async makeOneRequest(channelId: string) {
    const minTimeout = 5000
    this.status = 'pending'
    try {
      const { data } = await fetchStream(channelId, true)
      const nextTitle = data?.next?.length ? data.next[0] : null
      const now = dayjs()
      const nearestTime = nextTitle?.time || now.clone().add(5, 'seconds').toISOString()
      const diff = dayjs(nearestTime).diff(now, 'milliseconds')
      const timeout = Math.ceil(diff / 1000) * 1000 // round to seconds
      if (process?.env?.NODE_ENV !== 'production') {
        console.log(
          `${channelId}: Duration between now ${now.toISOString()} and the time of the nearest song ${nearestTime}: ${timeout} milliseconds`
        )
      }
      runInAction(() => {
        this.error = null
        this.updateStream(data)
        this.status = 'done'
      })
      return timeout || minTimeout
    } catch (error) {
      Sentry.captureException(error)
      runInAction(() => {
        this.status = 'error'
      })
      return minTimeout
    }
  }

  public async makeRequest() {
    const minTimeout = 5000
    this.status = 'pending'
    try {
      const { data } = await fetchStreams(true)
      const times = data
        .map((stream) => (stream.next?.length ? stream.next[0].time : null))
        .filter((val) => val !== null)
      const time = times.reduce((acc, curr) => (dayjs(curr).isBefore(dayjs(acc)) ? curr : acc), times[0])
      const now = dayjs()
      const timeout = dayjs(time).diff(now, 'milliseconds')
      runInAction(() => {
        this.error = null
        this.setStreams(data)
        this.status = 'done'
      })
      return Math.max(timeout, minTimeout)
    } catch (error) {
      Sentry.captureException(error)
      runInAction(() => {
        this.status = 'error'
      })
      return minTimeout
    }
  }

  setVolume(value: number) {
    this.volume = value
  }

  setLatency(value: number) {
    this.latency = value
  }

  get streamNames() {
    return this.streams.map((channel) => channel.displayName).join(', ')
  }

  get playingStream() {
    return this.streams ? this.streams.find((channel) => channel.id === this.playingStreamId) : undefined
  }

  get selectedStream() {
    return this.streams ? this.streams.find((channel) => channel.id === this.selectedStreamId) : undefined
  }

  get currentPlayingTitle() {
    return this.playingStream?.current
  }

  get streamUrl() {
    if (!this.playingStream) return undefined
    const { streamUri } = this.playingStream
    return `${AppConfig.streamUrl}${!this.isHD ? streamUri.replace('master', '64k') : `${streamUri}`}`
  }

  get djinglesIndex() {
    return this.jingles
      ? this.jingles.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.id]: currentValue
          }),
          {} as Record<string, DJingle>
        )
      : {}
  }

  get mediaIndex() {
    return (this.streams || [])
      .flatMap((stream) => {
        const streamShort = {
          id: stream.id,
          displayName: stream.displayName
        }
        return [
          { stream: streamShort, ...stream.current },
          ...(stream.next || []).map((value) => ({ stream: streamShort, ...value })),
          ...(stream.history || []).map((value) => ({ stream: streamShort, ...value }))
        ]
      })
      .reduce(
        (acc, current) => ({
          ...acc,
          [current.id]: current
        }),
        {}
      )
  }

  setFavoriteSelected(title: Title) {
    this.favoriteSelected = title && { ...title }
  }

  setOpenFavoriteModal(open: boolean) {
    this.openFavoriteModal = open
  }

  setSelectedStreamId(streamId?: string) {
    this.selectedStreamId = streamId
  }

  playNextStream() {
    if (!this.playingStreamId || this.offline) return
    const index = this.streams.findIndex((stream) => stream.id === this.playingStreamId)
    const nextIndex = index + 1
    const stream = this.streams[nextIndex < this.streams.length ? nextIndex : 0]
    RootStore.trackPause(this.playingStream)
    this.play(stream.id)
  }

  playPreviousStream() {
    if (!this.playingStreamId || this.offline) return
    const index = this.streams.findIndex((stream) => stream.id === this.playingStreamId)
    const nextIndex = index - 1
    const stream = this.streams[nextIndex < 0 ? this.streams.length - 1 : nextIndex]
    RootStore.trackPause(this.playingStream)
    this.play(stream.id)
  }

  stop() {
    this.playingStreamId = null
    this.playing = false
  }

  pause() {
    event('pause', {
      category: 'Stream',
      label: this.playingStreamId
    })
    this.playing = false
    RootStore.trackPause(this.playingStream)
  }

  setHD(isHD: boolean) {
    event('update quality', {
      category: 'Stream',
      label: isHD ? '256k' : '64k'
    })
    this.isHD = isHD
  }

  static trackPlay(stream: Stream) {
    if (stream) {
      amplitude.track('browser play', {
        stream: stream.displayName,
        ...stream.current
      })
    }
  }

  static trackPause(stream: Stream) {
    if (stream) {
      amplitude.track('browser pause', {
        stream: stream.displayName,
        ...stream.current
      })
    }
  }

  play(streamId: string) {
    if (this.offline) return
    event('play', {
      category: 'Stream',
      label: streamId
    })
    this.playingStreamId = null
    setTimeout(() => {
      runInAction(() => {
        this.playingStreamId = streamId
        this.playing = false
      })
    }, 10)
    setTimeout(() => {
      runInAction(() => {
        this.playing = true
        RootStore.trackPlay(this.playingStream)
      })
    }, 11)
  }

  toggle(streamId?: string) {
    const changingStream = this.playingStreamId !== streamId
    if (!this.playing) {
      this.play(streamId || this.playingStreamId)
      return
    }
    this.pause()
    if (changingStream) this.play(streamId)
  }

  public async fetchChannels() {
    this.status = 'pending'
    try {
      const response = await fetchStreams(true)
      runInAction(() => {
        this.error = null
        this.setStreams(response.data)
        this.status = 'done'
      })
    } catch (error) {
      runInAction(() => {
        this.status = 'error'
      })
    }
  }

  public async fetchJingles() {
    try {
      const response = await fetchJingles()
      runInAction(() => {
        this.jingles = [...response.data]
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  hydrate(data?: RootStoreHydration) {
    if (process?.env?.NODE_ENV !== 'production') {
      console.info('hydrate: ', data)
    }
    if (data) {
      if (data.streams && data.streams.length > 0) {
        this.setStreams(data.streams)
      }
      this.selectedStreamId = data.selectedStreamId
      this.selectors = data.selectors
    }
  }
}
