/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '@/stores/RootStoreProvider'
import OfflineModal from '@/components/OfflineModal'
import FavoriteModal from '@/components/FavoriteModal'
import { Detector } from 'react-detect-offline'
import { useTranslation } from 'next-i18next'
import useMediaSession from '@/hooks/useMediaSession'
import useOffline from '@/hooks/useOffline'
import Player from '@/components/Player'

export default observer(({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const store = useRootStore()
  const [headerClicked, setHeaderClicked] = useState(false)
  const { openFavoriteModal, playing, jingles } = store

  useOffline()
  useMediaSession()

  useEffect(() => {
    if (!jingles || jingles.length === 0) {
      ;(async () => store.fetchJingles())()
    }
  }, [])

  return (
    <div className="antialiased">
      <div className="flex flex-col items-center h-screen h-full">
        <Header
          onClick={() => {
            setHeaderClicked(true)
            store.setOpenFavoriteModal(true)
          }}
        />
        <main className="container flex flex-col justify-start items-center">{children}</main>
        <Footer />
      </div>
      <Player />
      <Detector
        render={({ online }) => <OfflineModal open={!online} text={playing ? t('offlinePlaying') : t('offline')} />}
      />
      <FavoriteModal
        initialView={headerClicked ? 'view2' : 'view1'}
        open={openFavoriteModal}
        onClose={() => {
          store.setOpenFavoriteModal(false)
          setHeaderClicked(false)
        }}
      />
    </div>
  )
})
