import * as React from 'react'
import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  variant?: 'quiet' | 'yellow'
}

const HeartIcon = ({ width = 26, height = 24, variant = 'quiet', ...props }: Props) => (
  <svg width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.096 3.153A6.367 6.367 0 0 0 18.296 1c-1.386 0-2.654.451-3.771 1.341A7.852 7.852 0 0 0 13 3.981a7.847 7.847 0 0 0-1.525-1.64C10.358 1.451 9.09 1 7.705 1a6.368 6.368 0 0 0-4.801 2.153C1.676 4.525 1 6.4 1 8.431c0 2.092.756 4.006 2.38 6.025 1.453 1.806 3.541 3.64 5.959 5.763.826.725 1.761 1.546 2.733 2.422.257.232.586.359.928.359.341 0 .671-.128.927-.359.972-.875 1.909-1.698 2.735-2.423 2.417-2.123 4.505-3.956 5.958-5.762C24.244 12.437 25 10.523 25 8.431c0-2.031-.676-3.906-1.904-5.278Z"
      fill={variant === 'yellow' ? '#FEE32D' : null}
      stroke={variant === 'yellow' ? '#FEE32D' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartIcon
