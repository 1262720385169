import { useEffect } from 'react'
import { parseTitle, safeNextImage } from '@/utils'
import { useRootStore } from '@/stores/RootStoreProvider'
import { useTranslation } from 'next-i18next'

const useMediaSession = () => {
  const { t } = useTranslation()
  const store = useRootStore()
  const { playing, currentPlayingTitle, playingStream } = store

  useEffect(() => {
    if (!('mediaSession' in navigator)) {
      console.log('Media Session Metadata is not supported')
      return
    }
    let metadata: MediaMetadata = null
    if (currentPlayingTitle) {
      console.log('Update Media Session Metadata', currentPlayingTitle.title)
      const { artist, song } = parseTitle(currentPlayingTitle?.title, t('defaultArtist'), playingStream.displayName)
      const { metadata: currentMetadata } = navigator.mediaSession
      if (currentMetadata && currentMetadata.title === song && currentMetadata.artist === artist) return
      metadata = new MediaMetadata({
        title: song,
        artist,
        artwork: [
          {
            src: safeNextImage(playingStream.id, currentPlayingTitle, 192, 192, true),
            sizes: '192x192',
            type: 'image/*'
          },
          {
            src: safeNextImage(playingStream.id, currentPlayingTitle, 256, 256, true),
            sizes: '256x256',
            type: 'image/*'
          },
          {
            src: safeNextImage(playingStream.id, currentPlayingTitle, 384, 384, true),
            sizes: '384x384',
            type: 'image/*'
          },
          {
            src: safeNextImage(playingStream.id, currentPlayingTitle, 512, 512, true),
            sizes: '512x512',
            type: 'image/*'
          }
        ]
      })
    }
    navigator.mediaSession.metadata = metadata
  }, [currentPlayingTitle?.id, playing])

  useEffect(() => {
    if ('mediaSession' in navigator) {
      const id = playingStream?.id
      navigator.mediaSession.setActionHandler('pause', () => {
        store.pause()
      })
      navigator.mediaSession.setActionHandler('play', () => {
        store.toggle(id)
      })
      navigator.mediaSession.setActionHandler('stop', () => {
        store.stop()
      })
      const noop = () => {
        // nothing to do
      }
      navigator.mediaSession.setActionHandler('seekbackward', noop)
      navigator.mediaSession.setActionHandler('seekforward', noop)
      navigator.mediaSession.setActionHandler('previoustrack', () => store.playPreviousStream())
      navigator.mediaSession.setActionHandler('nexttrack', () => store.playNextStream())
      navigator.mediaSession.setActionHandler('seekto', noop)
    }
  }, [playingStream?.id, playing])
}

export default useMediaSession
