import * as React from 'react'

const CloseIcon = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect x={7.707} y={6.904} width={12} height={1} rx={0.5} transform="rotate(45 7.707 6.904)" fill="#fff" />
    <rect x={7} y={15.389} width={12} height={1} rx={0.5} transform="rotate(-45 7 15.39)" fill="#fff" />
  </svg>
)

export default CloseIcon
