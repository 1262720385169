import React from 'react'
import { InfoIcon } from '@/components/icons'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import styles from './OfflineModal.module.scss'

interface Props {
  open: boolean
  text: string
}

export const Dialog = DialogPrimitive.Root

export default function OfflineModal({ open, text }: Props) {
  return (
    <Dialog open={open} modal>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content>
          <div className={`${styles.container} flex flex-row items-center p-5`}>
            <div className="pr-4 pt-1">
              <InfoIcon />
            </div>
            <span className={styles.message}>{text}</span>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </Dialog>
  )
}
