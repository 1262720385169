import React from 'react'
import { Copyright, Heading, Legal } from '@/components/Typography'
import { useTranslation } from 'next-i18next'
import { FacebookIcon, InstagramIcon, RadioIcon, TwitterIcon } from '@/components/icons'
import IconHolder from './IconHolder'
import TakeMore from './TakeMore'
import Contact from './Contact'

export default function Footer({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation()

  return (
    <footer className="container">
      <div className="flex flex-row xs:flex-col sm:flex-col md:flex-row xs:px-6 sm:px-6 space-x-8 xs:space-x-0 sm:space-x-0 sm:pt-12 lg:pb-2 xl:pb-2">
        <section className="flex-1 pt-4 xs:pt-6 border-t border-solid border-gray-700 xs:border-none">
          <div className="flex flex-row xs:flex-col space-x-2 pt-2 space-x-16 xs:space-x-0">
            <div className="flex flex-col xs:items-end xs:border-b xs:border-t border-solid border-gray-700 xs:pb-8 xs:pt-6">
              <Heading className="xs:hidden">{t('follow')}</Heading>
              <div className="xs:flex xs:w-full">
                <div className="flex flex-grow items-end pb-1 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">
                  <RadioIcon className="xs:logo-small transition-all" />
                </div>
                <div className="flex flex-row space-x-2 pt-2">
                  <IconHolder href="https://www.facebook.com/kyivstar">
                    <FacebookIcon />
                  </IconHolder>
                  <IconHolder href="https://twitter.com/TwiyKyivstar">
                    <TwitterIcon />
                  </IconHolder>
                  <IconHolder href="https://www.instagram.com/kyivstar.official/">
                    <InstagramIcon />
                  </IconHolder>
                </div>
              </div>
            </div>

            <TakeMore heading={t('takeMore')} />
          </div>
        </section>
        <section className="flex flex-1 flex-col space-y-2 pb-6 xs:pt-6 sm:pt-6 md:pl-6 md:pt-0">
          <Contact heading={t('contact')} />
          <Legal>{t('legal')}</Legal>
          <Copyright>{t('copyright')}</Copyright>
        </section>
        {children}
      </div>
    </footer>
  )
}
