import * as React from 'react'
import { SVGProps } from 'react'

const PauseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={12} y={3} width={5} height={14} rx={1} fill="#0F1114" />
    <rect x={3} y={3} width={5} height={14} rx={1} fill="#0F1114" />
  </svg>
)

export default PauseIcon
